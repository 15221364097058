import React from "react"
import UpdateSubscriptionChildren from '../components/pages/UpdateSubscriptionChildren'
import Layout from "../layouts/layout"

const AddChildrenPage = props => {
  if (typeof window === "undefined") return null
  return (
    <Layout>
      <UpdateSubscriptionChildren {...props} />
    </Layout>
  )
}

export default AddChildrenPage
