import { navigate } from 'gatsby'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { NON_DECIMAL_CURRENCIES } from '../../../constants/currencies'
import useData from './useData'

const UpdateSubscriptionState = () => {
  const { loading, data, findPlan, updatePlan, getPlan } = useData()
  const [newInterval, setNewInterval] = useState(data?.subscription.interval)
  const [nbChildren, setNbChildren] = useState(data?.maxIncludedChildren)
  const [newPlan, setNewPlan] = useState<any>()

  const newData = useMemo(() => {
    return {
      interval: newInterval,
      nbChildren
    }
  }, [newInterval, nbChildren])

  const hasUpdated = useMemo(() => {
    return newData.interval !== data?.subscription.interval ||
    newData.nbChildren !== data?.maxIncludedChildren
  }, [data, newData])

  const fetchPlan = async (args?: { interval: 'monthly' | 'quarterly' | 'yearly' }) => {
    if (!data?.subscription.planId) return
    let plan = await getPlan(data?.subscription?.planId)
    if (!plan || !plan.tiers || plan.tiers.length === 0) {
      plan = await findPlan(data?.subscription.currency || 'usd', args?.interval || data?.subscription.interval || 'yearly')
    }
    setNewPlan(plan)
  }
  
  useEffect(() => {
    if (!data?.tiered && !newPlan) fetchPlan()
  }, [newPlan, data])

  useEffect(() => {
    if (!loading && !newInterval) {
      setNbChildren(data?.maxIncludedChildren)
      setNewInterval(data?.subscription.interval)
    }
  }, [loading])

  const maxNbChildren = useMemo(() => {
    if (loading || !newPlan) return 0
    const maxTier = Math.max(...newPlan.tiers.map(t => t.upTo || 0))
    return maxTier
  }, [newPlan])

  useEffect(() => {
    if (hasUpdated) fetchPlan({ interval: newData.interval || 'yearly' })
  }, [hasUpdated, newData])

  const updateNewData = (payload) => {
    if (payload.interval) setNewInterval(payload.interval)
    if (payload.nbChildren) setNbChildren(payload.nbChildren)
  }

  const newAmounts = useMemo(() => {
    if (!newPlan) return null
    const maxTier = Math.max(...newPlan.tiers.map(t => t.upTo || 0))
    if (newData.nbChildren === undefined || newData.nbChildren > maxTier + 1) {
      return null
    }
    let price
    if (newData.nbChildren > maxTier) {
      const tier = newPlan.tiers.filter(t => t.upTo === null)[0]
      price = tier.amount + (tier.unitAmount || 0) * newData.nbChildren
    } else {
      const tier = newPlan.tiers.filter(t => t.upTo === newData.nbChildren)[0]
      price = tier.amount + (tier.unitAmount || 0) * newData.nbChildren
    }
    if (!data?.subscription.currency) return null
    if (NON_DECIMAL_CURRENCIES.indexOf(data?.subscription.currency) < 0) {
      price /= 100
    }
    return {
      amount: price,
      discounted: data?.getAmountWithSubscriptionDiscount(price),
      isDiscounted: price !== data?.getAmountWithSubscriptionDiscount(price)
    }
  }, [data, newPlan])

  const getIntervalLabel = (interval) => {
    if (interval === 'monthly') return '/ month'
    if (interval === 'quarterly') return '/ 3 months'
    if (interval === 'yearly') return '/ year'
    return ''
  }

  const newAmountsFormatted = useMemo(() => {
    if (!newAmounts) return null
    return {
      amount: `${new Intl.NumberFormat('us-US',
        { style: 'currency', currency: data?.subscription.currency.toUpperCase()
      }).format(newAmounts.amount || 0)} ${getIntervalLabel(newInterval)}`,
      discounted: `${new Intl.NumberFormat('us-US',
        { style: 'currency', currency: data?.subscription.currency.toUpperCase()
      }).format(newAmounts.discounted || 0)} ${getIntervalLabel(newInterval)}`,
      isDiscounted: newAmounts.isDiscounted
    }
  }, [newAmounts, newInterval])

  const canSubmit = useMemo(() => {
    return hasUpdated
  }, [hasUpdated])

  const onUpdatePlan = async () => {
    await updatePlan(newPlan.id, nbChildren)
    navigate('/account?forceRefetch=true')
  }

  return {
    loading,
    data,
    newData,
    updateNewData,
    newPlan,
    newAmounts: newAmountsFormatted,
    getIntervalLabel,
    canSubmit,
    onUpdatePlan,
    maxNbChildren
  }
}

const UpdateSubscriptionStateContainer = createContainer(UpdateSubscriptionState)

export const UpdateSubscriptionStateProvider = UpdateSubscriptionStateContainer.Provider
export const useUpdateSubscriptionState = UpdateSubscriptionStateContainer.useContainer
