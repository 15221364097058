import { useApolloClient } from '@apollo/client'
import useStripeSusbcription from 'hooks/useStripeSusbcription'
import { useEffect, useState } from 'react'
import { CurrentUserState } from '../../../state/CurrentUserState'
import FIND_PLAN from './graphql/findPlan.graphql'
import GET_DATA from './graphql/getData.graphql'
import GET_PLAN from './graphql/getPlan.graphql'
import UPDATE_USER from './graphql/updateUser.graphql'

const useData = () => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [rawData, setRawData] = useState<any>()
  const { currentUser } = CurrentUserState.useContainer()

  const subscription = useStripeSusbcription(rawData?.subscription)

  const init = async (forceRefetch = false) => {
    const { data } = await client.query({
      query: GET_DATA,
      fetchPolicy: forceRefetch ? 'network-only' : undefined,
      errorPolicy: 'all'
    })
    setRawData(data.me)
    setLoading(false)
  }

  const findPlan = async (currency: string, term: 'quarterly' | 'yearly' | 'monthly') => {
    const { data } = await client.query({
      query: FIND_PLAN,
      variables: {
        currency,
        term
      }
    })

    let plan = { ...data.findPlan }
    if (plan.tiers?.length > 0) {
      plan = {
        ...plan,
        tiers: plan.tiers.reduce((acc, tier, i) => {
          let currTier = { ...tier }
          if (currTier.upTo === null) {
            currTier = { ...currTier, upTo: Math.max(...plan.tiers.map(t => t.upTo || 0)) + 1 }
          }
          if (plan.tiers[i - 1] && plan.tiers[i - 1].upTo !== currTier.upTo - 1) {
            return [...acc, { ...currTier, upTo: currTier.upTo - 1 }, currTier]
          }
          return [...acc, currTier]
        }, [])
      }
    }
    return plan
  }

  const getPlan = async (id?: string) => {
    const { data } = await client.query({
      query: GET_PLAN,
      variables: {
        id: id || subscription?.subscription.planId
      },
      fetchPolicy: 'network-only'
    })

    let plan = { ...data.plan }
    if (plan.tiers?.length > 0) {
      plan = {
        ...plan,
        tiers: plan.tiers.reduce((acc, tier, i) => {
          let currTier = { ...tier }
          if (currTier.upTo === null) {
            currTier = { ...currTier, upTo: Math.max(...plan.tiers.map(t => t.upTo || 0)) + 1 }
          }
          if (plan.tiers[i - 1] && plan.tiers[i - 1].upTo !== currTier.upTo - 1) {
            return [...acc, { ...currTier, upTo: currTier.upTo - 1 }, currTier]
          }
          return [...acc, currTier]
        }, [])
      }
    }
    return plan
  }

  const updatePlan = async (planId: string, quantity) => {
    if (!currentUser) return
    const { data } = await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        user: {
          _id: currentUser?._id,
          subscription: {
            plan: planId,
            quantity
          }
        }
      }
    })
    return data
  }

  const refetch = () => init(true)

  useEffect(() => {
    init()
  }, [])


  return { loading, data: subscription, refetch, findPlan, getPlan, updatePlan }
}

export default useData
