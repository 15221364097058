import BoxGroup from 'components/UI/BoxGroup'
import Centered from 'components/UI/Centered'
import Loader from 'components/UI/Loader'
import Select from 'components/UI/Select'
import { navigate } from 'gatsby'
import { ArrowLeft, Button } from 'nzk-react-components'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { CurrentUserState } from '../../../state/CurrentUserState'
import EditableField from '../AccountSettings/components/EditableField'
import { BoxGroupItem } from '../AccountSettings/index.styles'
import { UpdateSubscriptionStateProvider, useUpdateSubscriptionState } from './index.state'

const Wrapper = styled.div`
  color: #fff;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 65px;
`

const BackButton = styled.div`
  position: absolute;
  top: 100px;
  left: 30px;
`

const LoaderWrapper = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
`

const Interval = styled.div`
  text-transform: capitalize;
`

const Info = styled.div`
  font-family: 'Libre Baskerville';
`

const DiscountedPrice = styled.div`
  > :first-child {
    text-decoration: line-through;
    margin-right: 8px;
  }
`

const UpdateSubscription = () => {
  const { loading, data, newData, updateNewData, newAmounts, getIntervalLabel, canSubmit, onUpdatePlan, maxNbChildren, newPlan } = useUpdateSubscriptionState()
  const { currentUser } = CurrentUserState.useContainer()

  const nbChildrenOptions = useMemo(() => {
    if (newPlan?.tiers) {
      return newPlan.tiers.map(t => ({ label: `${t.upTo}`, value: `${t.upTo}` })).filter(({ value }) => parseInt(value, 10) >= (currentUser?.children.length || 0))
    }
    return new Array(maxNbChildren).fill(null).map((_, i) => ({ label: `${i + 1}`, value: `${i + 1}` })).filter(({ value }) => parseInt(value, 10) >= (data?.maxIncludedChildren || 0))
  }, [maxNbChildren, newPlan])

  if (loading) return <LoaderWrapper><Loader color='#fff' /></LoaderWrapper>
  return <Wrapper>
    <BackButton>
      <Button theme='primary' size='regular' round onClick={() => {
        navigate('/account')
      }}>
        <ArrowLeft width='100%' height='100%' />
      </Button>
    </BackButton>
    <BoxGroup>
      <BoxGroupItem>
        <div>Term</div>
        { /* <EditableField editingContent={
          <Select options={[
            { label: 'Yearly', value: 'yearly' },
            { label: 'Quarterly', value: 'quarterly' },
            { label: 'Monthly', value: 'monthly' },
          ]} value={newData.interval || ''} onChange={(value) => updateNewData({ interval: value })} />
        } onSave={() => Promise.resolve()}> */ }
        <Interval>{ newData.interval }</Interval>
      { /* </EditableField> */ }
      </BoxGroupItem>
      { (true || data?.tiered) && <BoxGroupItem>
        <div>Number of children</div>
        <EditableField editingContent={
          <Select options={nbChildrenOptions} value={`${newData.nbChildren}`} onChange={(value) => updateNewData({ nbChildren: parseInt(value, 10) })} />
        } onSave={() => Promise.resolve()}>
          <div>{ newData.nbChildren }</div>
        </EditableField>
      </BoxGroupItem> }
      { (false && !data?.tiered) && <BoxGroupItem>
        <div>Number of children</div>
        <div>{ data?.maxIncludedChildren }</div>
        <Info>If you wish to change the number of children for your plan, please contact <a href="mailto:support@nightzookeeper.com">support@nightzookeeper.com</a></Info>
      </BoxGroupItem> }
      { data?.subscription.renewalCost && <BoxGroupItem>
        <div>Current Subscription Cost</div>
        { data?.subscription.discounted
          ? <DiscountedPrice>
            <div>{ data.subscription.noDiscountPrice } {getIntervalLabel(data.subscription.interval)}</div>
            <div>{ data?.subscription.renewalCost } {getIntervalLabel(data.subscription.interval)} with {data.subscription.discountAmount}% OFF discount</div>
          </DiscountedPrice>
          : <div>{data?.subscription.renewalCost}</div>
        }
      </BoxGroupItem> }
      {newAmounts?.amount && canSubmit && <BoxGroupItem>
        <div>New Subscription Cost</div>
        { newAmounts?.isDiscounted
          ? <DiscountedPrice>
            <div>{ newAmounts?.amount }</div>
            <div>{ newAmounts?.discounted } with {data?.subscription.discountAmount}% OFF discount</div>
          </DiscountedPrice>
          : <div>{newAmounts?.amount}</div>
        }
      </BoxGroupItem> }
      { canSubmit && <BoxGroupItem padding='8px 24px'>
        <Centered vertically>
          <Button size='regular' theme='confirm' onClick={onUpdatePlan}>
            Submit
          </Button>
        </Centered>
      </BoxGroupItem> }
    </BoxGroup>
  </Wrapper>
}

UpdateSubscription.propTypes = {

}

UpdateSubscription.defaultProps = {

}

export default () => {
  return <UpdateSubscriptionStateProvider>
    <UpdateSubscription />
  </UpdateSubscriptionStateProvider>
}
